$().ready(function(){
	setupSlideMenu();
	if ($("form").length){
		$("form").each(function(){
			if (!$(this).hasClass("search")){
				$(this).validate();
			}
			$(this).addClass("floatlabel-active");
			$(this).find(".js-float-wrap").FloatLabel();
		});
	}
	if ($(".banners .banner").length > 1){
		scrollBanners();
	}
	$("#map-click").click(function(){
		// shield the map on mobile for easy scrolling
		$(this).hide();
	});
});

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
	$("div.archive h4").each(function(index, element){
		var $el = $(element);
		var expander = $("<span class=\"expander\"></span>");
		$el.append(expander);
		$el.click(function(e) {
			e.stopPropagation();
			$el.parent().toggleClass("expand-active");
		});
	});
}

export function slide(offset, $bnav, $scroller){
	var $distance = $(".banner").outerWidth();
	// var $before = $scroller.find(".banner.active").prev();
	var $after = $scroller.find(".banner.active").next();

	if (!$scroller.data("animating")){
		$scroller.data("animating", true);
		var newPos;
		if (offset < 0) { //fwd next
			if ($after.length>0){
				newPos = $scroller.scrollLeft() + $distance;
				$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
					$scroller.data("animating", false);
				});
				// set active
				$scroller.find(".banner.active").removeClass("active");
				$after.addClass("active");
				var i= $after.index();
				$bnav.removeClass("active");
				$bnav.eq(i).addClass("active");
			} else { /* you're at the end - go to first */
				var startPos = $scroller.scrollLeft(0);
				$scroller.stop().animate({scrollLeft:startPos},500,"swing", function(){
					$scroller.data("animating", false);
				});
				// set active
				$scroller.find(".banner.active").removeClass("active");
				$scroller.find(".banner").first().addClass("active");
				$bnav.removeClass("active");
				$bnav.eq(0).addClass("active");
			}
		} else { //button index
			var $index = offset;
			var $new = $scroller.find(".banner").eq($index);
			newPos = $scroller.scrollLeft() + $new.position().left;
			$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
				$scroller.data("animating", false);
			});
			clearInterval(rotInt);
			/* set active */
			$bnav.removeClass("active");
			$bnav.eq($index).addClass("active");
			$scroller.find(".banner.active").removeClass("active");
			$new.addClass("active");
			rotInt = setInterval(function(){slide(-1, $bnav, $scroller);}, 5000);
		}
	}
}

var rotInt;
export function scrollBanners(){
	$(".banners").each(function(){
		if ($(this).find(".banner").length > 0){
			$(this).find(".banner-navigation").show();
			var $scroller = $(this).find(".banner-stage");
			var $bnav = $(this).find(".banner-nav-item");


			/* auto rotate */
			rotInt = setInterval(function(){slide(-1, $bnav, $scroller);}, 5000);
			/* clicks */
			$bnav.each(function(index, element){
				var $button = $(element);
				$button.on("click touch", function(){
					var index = $(this).index();
					slide(index, $bnav, $scroller);
				});
			});
		}
	});
}
